.LeftSideBar {
  display: flex;
  flex-flow: column;
  // position: fixed;
  // left: 5%;
  // top: 90px;
  // max-width: 20%;

  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.leftSideBar__link {
  // margin-left: 20px;
  height: 47px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #dee0e8;
    border-radius: 6px;
  }
}

.leftSideBar__linkTxt {
  margin-left: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #6c7289;
}

.leftSideBar__onlineVotingBox {
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(36 36 36 / 10%);
  padding: 25px 20px;
  margin-top: 40px;
  // min-width: /230px;
  width: 239px;

  p {
    font-weight: 600;
    font-size: 16px;

    color: #3f4254;
  }

  button {
    margin-top: 10px;
    background-color: #009ef7;
    border-radius: 6px;
    border: none;
    padding: 8px 20px;
    color: #fff;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
