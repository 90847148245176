.createReply__inputWrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
}

.createReply__tagLink {
  width: 100%;
  max-width: fit-content;
}

.createReply__tag {
  width: 100%;
  max-width: fit-content;
  color: #3699ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.createReply__input {
  background-color: #f2f3f7;
  padding: 0px;
}

.createReply__imgWrap {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: 10px;
}
