$civik-dark-blue: #3f4254;
$civik-dark-grey: #6c7289;
$civik-light-grey: #b5b5c3;
$civik-primary-blue: #3699ff;

.feed__postCommentsDateTxt {
  margin-left: 7px;
}

.feed__postCommentLetterWrapper {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-left: 0px;
}

.feed__postCommentsLiked {
  font-weight: 600;
  margin-top: 1px;
  color: $civik-primary-blue;
}

.feed__postCommentsLikedText {
  color: $civik-primary-blue !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .emojiPicker {
    display: none;
  }
}
