$civik-dark-blue: #3f4254;
$civik-dark-grey: #6c7289;
$civik-light-grey: #b5b5c3;
$civik-primary-blue: #3699ff;

.PetitionSign {
  padding: 100px 25%;
}

.petitionSign__pdf {
  background-color: #fff;
  width: 100%;
  padding: 50px;
}

.petitionsSign__pdfHeader {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 2px solid #000;
  padding-bottom: 20px;

  .petitionsSign__pdfHeaderTitle {
    font-size: 28px;
  }
}

.petitionsSign__pdfOrganizer {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.petitionsSign__pdfOrganizerTxt,
.petitionsSign__pdfDate {
  display: flex;
  flex-flow: column;
}

.petitionsSign__pdfOrganizeTxtLabel,
.petitionsSign__pdfDateTxtLabel {
  color: $civik-dark-grey;
}

.petitionsSign__pdfOrganizeTxtValue,
.petitionsSign__pdfDateTxtValue {
  font-weight: 600;
}

.petitionsSign__pdfDate {
  display: flex;
  flex-flow: column;
}

.petitionsSign__pdfDetails {
  padding: 30px 0px;
  display: flex;
  flex-flow: column;
}

.petitionsSign__pdfDetailsTxt {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.petitionsSign__pdfDetailsTxtLabel {
  color: $civik-dark-grey;
}

.petitionsSign__pdfDetailsTxtValue {
  font-size: 16px;
  font-weight: 600;
}

.petitionsSign__pdfTo {
  margin-left: 50px;
}

.petitionsSign__pdfWardNumber {
  margin-left: 15px;
}

.petitionsSign__pdfDetailsWardRow {
  margin-top: 10px;
}

.petitionsSign__pdfTitle {
  font-size: 28px;
}

.petitionsSign__pdfDescriptionLabel {
  border-bottom: 2px solid #000;
  margin-top: 25px;
  padding-bottom: 10px;
}

.petitionsSign__pdfDescriptionValue {
  margin-top: 10px;
  font-size: 14px;
  line-height: 23px;
}

.petitionsSign__pdfKeyPointsWrap {
  display: flex;
  flex-flow: column;
  margin-top: 25px;
  margin-bottom: 25px;
}

.petitionsSign__pdfKeyPointImg {
  margin-right: 20px;
}

.petitionsSign__pdfKeyPoint {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;
}

.petitionsSign__pdfKeyPointTxt {
  font-size: 18px;
  font-weight: 600;
}
.feed__postCommentBarProfileImg {
  margin-right: 15px;
}

.petitionsSign__pdfAttachmentWrap {
  display: flex;
  flex-flow: column;
  padding-top: 25px;
  padding-bottom: 25px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.petitionsSign__pdfAttachmentTxt {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
}

.petitionsSign__pdfSignWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.petitionsSign__pdfSignBanner {
  background-color: $civik-primary-blue;
  padding: 5px 30px;
  width: 90px;
  margin-left: -50px;
  color: #fff;
  font-weight: 500;
}

.arrow-right {
  width: 0;
  height: 0;

  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-left: 15px solid $civik-primary-blue;
}

.petitionsSign__pdfSignBannerWrap {
  display: flex;
  flex-flow: row;
}

.petitionsSign__pdfSignTxt {
  margin-left: 100px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
}
