.RightSideBar {
  display: flex;
  flex-flow: column;
  // position: fixed;
  // right: 5%;
  // top: 80px;
  // width: 20%;
  // z-index: 0;

  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.rightSideBar__weather {
  padding: 20px;
  width: 239px;
  height: 210px;
  background: #ffffff;
  /* Box Drop Shadow-2 */
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 12px;
}

.rightSideBar__weatherLocation {
  color: #414b5a;
}

.rightSideBar__weatherDate {
  color: #414b5a;
}

.rightSideBar__weatherLabel {
  color: #808080;
}

.rightSideBar__weatherTemperature {
  color: #414b5a;
  font-size: 40px;
}

.rightSideBar__weatherFeelsLike {
  color: #414b5a;
}

.rightSideBar__weatherIcon {
  width: 80px;
  color: #414b5a;
}

.rightSideBar__weatherInfoContainer {
  justify-content: space-between;
}
