.NewsGuard {
  font-family: Poppins;
}

.newsguard__btn {
  margin-top: -12px;
}

.newsguard__btnTxtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.newsguard__btnTxt {
  font-size: 9px;
  font-weight: 600;
  color: #6c7289;
  margin-left: 5px;
}

.divider {
  border-bottom: 1px solid #d0d0d0;
  margin: 0 -15px;
}
