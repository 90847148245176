.privacy-modal {
  right: 34%;
  @media only screen and (max-width: 1200px) {
    margin-top: -150px;
    right: 10%;
  }
  // margin-top: 16px;
}

.createReply__privacyDropdown {
  z-index: 1;
  position: absolute;

  border-radius: 4px;

  background: yellow;
  box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);
}
