.home__wrapper {
  height: 100%;
  width: 100%;
  padding: 80px;
}

.Home {
  display: flex;
  flex-flow: row;
  justify-content: center;
  // align-items: center;

  height: 100%;

  // max-width: 1145px;
}

// .home__center {
//   width: 50%;
// }

.Post {
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 80px;
  height: 100%;
  width: 100%;
}

.SinglePost {
  margin-top: -20px;
  width: 100%;

  justify-content: center;
  align-items: center;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1200px) {
  .Post {
    padding: 0px;
  }

  .Home {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    padding: 0px;
  }

  .SinglePost {
    margin-top: 70px;
    margin-bottom: 80px;
  }

  .home__wrapper {
    padding: 0px;
  }
}
