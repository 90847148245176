.civik-candidate-label,
.civik-politician-label {
  border: 1.5px solid #aa7762;
  padding: 0px 10px;
  color: #aa7762;
  margin-left: 25px;
  border-radius: 6px;
  font-weight: 500;
}

.civik-politician-label {
  color: #3699ff;
  border: 1.5px solid #3699ff;
}

.profile-header-wrap {
  margin-top: 30px;
}
