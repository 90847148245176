.SelectField {
  height: 50px;

  background: #f5f8fa;
  padding-left: 20px;

  border-radius: 8px;
  border: none;
  width: 100%;

  font-size: 14px;
  font-weight: 500;
  color: #a1a5b7;

  &:focus {
    outline: none;
  }
}

.SelectFieldSmall {
  height: 40px;
  padding-left: 10px;
  background: #f5f8fa;

  border-radius: 8px;
  border: none;
  width: 100px;

  font-size: 14px;
  font-weight: 600;
  color: #a1a5b7;

  &:focus {
    outline: none;
  }
}
