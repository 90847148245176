.mobile__topNavbar {
  display: none;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .mobile__topNavbar {
    display: block;

    background-color: #fff;
    height: 70px;
    width: 100%;

    position: fixed;
    top: 0;

    box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
  }

  .mobile__topNavbar__logo {
    height: 30px;
    margin-top: 20px;
  }

  .mobile__sideNavbar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .navbar__elipsisProfileImg,
  .navbar__elipsisNameLetterWrap,
  .navbar__elipsisNameLetter {
    margin-left: 0;
    width: 46px;
    height: 46px;
  }

  .mobile__sideNavbarName {
    color: #3f4254;
    font-weight: 600;
    margin-left: 15px;
    font-size: 14px;
  }

  .mobile__sideNavbarProfileWrap {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .mobile__sideNavbarProfileTxtWrap {
    display: flex;
    flex-flow: column;
  }

  .mobile__sideNavbarProfileLink {
    margin-left: 15px;

    font-weight: 500;

    /* Primary Blue */
    color: #3699ff;
  }

  .mobile__sideNavbarSeparator {
    border: 1px solid #cbcbcb;
    width: 100%;
    margin: 0px;
  }

  .mobile__sideNavbarClose {
    position: absolute;
    right: 20px;
  }

  .mobile__sideNavbarLinks {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 20px 30px;

    span {
      color: #3f4254;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  .mobile__sideNavbarVoting {
    width: 85%;
    margin-left: 20px;
    margin-top: 20px;

    margin-bottom: 18px;
    p {
      font-size: 14px;
    }
  }

  .mobile__sideNavbarWeather {
    margin-left: 20px;
    width: 85%;
  }

  .mobile__sideNavbarLogout {
    border: 1px solid #3699ff;
    border-radius: 6px;

    padding: 10px;
    width: 85%;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    background-color: #fff;

    span {
      font-weight: 500;
      color: #3699ff;
      font-size: 15px;
    }
  }
}

