.TextField {
  height: 45px;
  padding: 20px;
  background: #f5f8fa;
  padding-left: 25px;
  border-radius: 8px;
  border: none;
  width: 100%;
  color: #3f4254;

  &:focus {
    outline: none;
  }
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}
