$civik-dark-blue: #3f4254;
$civik-dark-grey: #6c7289;
$civik-light-grey: #b5b5c3;
$civik-primary-blue: #3699ff;

.Feed {
  max-width: 601px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.feed__bar {
  // margin-top: 50px;
  // width: 100%;
  height: 140px;
  margin-left: 30px;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 12px;
}

.feed__barBtn {
  width: 100%;
  background-color: #eef2f5;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 27px 20px 20px;
  border-radius: 6px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .feed__barBtnTxt {
    color: $civik-dark-grey;
    font-weight: 500;
  }
}

// filter bar ----------

.feed__postFilterBar {
  padding: 0px 0px;
  margin-top: 18px;

  margin-right: 10px;
  border-radius: 6px;
  align-self: flex-end;
}

.feed__postFilterBarTxtWrap {
  display: flex;
  flex-flow: row;
}

.feed__postFilterBarTxt {
}

.feed__postFilterDropdown {
  // padding: 7px 10px;
  background-color: #fff;
  border: none;
  border-radius: 6px;

  margin-right: 20px;

  height: 38px;
  min-width: 92px;
  width: fit-content;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 0px 10px rgb(36 36 36 / 10%);

  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.feed__postFilterDropdownTxt {
  margin-left: 14px;
  // margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #6c7289;
}

.feed__postFilterDropdownChevron {
  color: #6c7289;
  margin-right: 10px;
  margin-left: 10px;
}

.feed__postFilterOptions {
  // top: 55px;
  // right: 145px;

  width: 200px;
}

.feed__postFilterOptionsTxt {
  font-weight: 500;
  font-size: 12px;

  color: #3f4254;
}

//
.feed__barIconsWrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  div:last-child {
    margin-left: 50px;
  }
}

.feed__barIcon {
  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .feed__barIconTxt {
    margin-left: 10px;
    color: $civik-dark-grey;
    font-weight: 500;
  }
}

.feed__barIconBtn {
  height: 35px;
  padding: 8px;
  width: fit-content;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
    background-color: #eef2f5;

    border-radius: 8px;
  }
}

.navbar__barNameLetterWrap {
  background-color: #c8e3ff;
  padding: 8px;
  height: 45px;
  width: 50px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}

.navbar__elipsisProfileImg {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  margin-left: 20px;
}

.feed__barTopWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.navbar__barNameLetter {
  color: #3699ff;

  font-size: 21px;
}

//  posts

.feed__postsContainer {
  display: flex;
  flex-flow: column;
}

.feed__post {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 12px;
  padding: 20px 30px 15px 30px;
}

.feed__postProfileOuterWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.feed__postProfileInnerWrap {
  display: flex;
  flex-flow: column;

  align-items: flex-start;
}

.feed__postProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 15px;
}

.feed__postProfileInnerWrapName {
  $civik-dark-blue: #3f4254;
  font-weight: 600;
}

.feed__postProfileInnerWrapDate {
  color: $civik-light-grey;
  font-size: 12px;
}

.feed__postHeader {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.feed__postTypeOuterWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.feed__postTypeWrap {
  border: 1px solid #50cd89;
  height: 21px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  margin-right: 10px;
}

.feed__postTypeTxt {
  font-weight: 500;
  color: #50cd89;
  font-size: 11px;
}

// post body

.feed__postBody {
  margin-top: 20px;
  margin-bottom: 20px;
}

.feed__postBodyTitle {
  color: #000;
  line-height: 20px;
  margin-bottom: 15px;
}

.feed__postBodyTxt {
  font-size: 13px;
}

// feed__postReactions

.feed__postReactions {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.feed__postReactionsLikeImg {
  width: 16px;
  height: 16px;
}

.feed__postReactionsStatsWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.feed__postReactionsCommentWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.feed__postReactionsStatsTxt,
.feed__postReactionsCommentStat,
.feed__postReactionsShareStat {
  margin-left: 7px;
  font-weight: 700;
  font-size: 12px;
  color: $civik-dark-grey;
}

.feed__postReactionsCommentTxt,
.feed__postReactionsShareTxt {
  font-size: 11px;
  color: $civik-dark-grey;
  margin-left: 4px;
}

.feed__postReactionsShareImg {
  margin-left: 30px;
}

// feed__postActionBar

.feed__postActionBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
  border-top: 1px solid #f2f3f7;
  border-bottom: 1px solid #f2f3f7;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.feed__postActionBarBtn {
  // height: 5px;
  // width: 100px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  // background-color: #f5f7f9;

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #f5f7f9;
    opacity: 0.7;
    cursor: pointer;
  }
}

.feed__postActionBarBtnLiked path {
  fill: $civik-primary-blue;
}

// .feed__postElipsisContainer {
//   height: 35px;
//   width: 35px;
//   margin-left: 5px;
//   // background-color: #f5f7f9;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     opacity: 0.7;
//     cursor: pointer;
//     background-color: #f5f7f9;
//     border-radius: 6px;

//     .feed__postElipsis circle {
//       fill: #3699ff;
//     }
//   }
// }

.feed__postActionBarBtnImg {
  width: 22px;
}

.feed__postActionBarBtnTxt {
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  color: $civik-dark-grey;
}

// comment bar
.feed__postCommentBar {
  margin-top: 8px;

  display: flex;
  flex-flow: row;
  width: 100%;
}

.feed__postCommentBarInputContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-right: 10px;
}

.feed__postCommentBarInputWrap {
  // margin-left: 15px;

  padding: 6px;
  padding-left: 10px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 400;
  background-color: #eef2f5;
  border: none;
  border-radius: 6px;

  color: #6c7289;

  display: flex;
  flex-flow: row;
  justify-content: space-between;

  width: 100%;
}

.feed__postCommentBarInput {
  background-color: #eef2f5;
  border: none;
  padding: 4px;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.feed__postCommentBarImgIcon,
.feed__postCommentBarEmojiIcon {
  width: 18px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.feed__postCommentBarEmojiIcon {
  margin-left: 10px;
}

.feed__postCommentBarProfileImg {
  width: 35px;
  height: 35px;
  border-radius: 6px;
}

.feed__postCommentBarLetterWrap {
  background-color: #c8e3ff;

  height: 35px;
  width: 35px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.feed__postHeaderLetterWrap {
  margin-right: 15px;
}

.feed__postElipsisContainer {
  height: 35px;
  width: 35px;
  margin-left: 5px;
  // background-color: #f5f7f9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
    background-color: #f5f7f9;
    border-radius: 6px;

    .feed__postElipsis circle {
      fill: #3699ff;
    }
  }
}

// post options
.dropdown__postOptions {
  // left: 0;
}

.feed__postBodyPreviewImg {
  height: 280px;
  width: 100%;

  border-radius: 15px;
}

.feed__postBodyNewsOverlay {
  height: 80px;
  width: 100%;

  background-color: #f2f3f7;

  position: relative;
  margin-top: -79px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 20px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.feed__postBodyNewsMetaTitle {
  color: #000;
}

.feed__barProfileImg {
  height: 45px;
  width: 50px;
  margin-left: 25px;
  border-radius: 6px;
}

.feed__postBodyNewsMetaUrl {
  color: $civik-dark-grey;
}

.feed__postBodyNewsImg {
  &:hover {
    cursor: pointer;
  }
}

.feed_loadingContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.feed__loader {
  width: 40px;
}

.feed__postActionBarBtnLiked {
}

.feed__postActionBarBtnTxtLiked {
  color: $civik-primary-blue;
}

.feed__postCopiedTooltip {
  position: fixed;
  background-color: black;
  color: #fff;
  padding: 10px;
  border-radius: 12px;

  bottom: 50%;
  left: 50%;
  z-index: 99999;
}

.feed__postCommentBarContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-right: 20px;
}

.feed__postCommentBarEnterTxt {
  margin-left: 15px;
  margin-top: 5px;
}

.feed__postCommentBarInputBtn {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

// COMMENTS
.feed__postComments {
  margin-top: 15px;
  margin-bottom: 15px;
}
.feed__postCommentsHeader {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.feed__postCommentsHeaderTxt {
  color: $civik-dark-grey;
  font-weight: 500;
  font-size: 12px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.feed__postCommentsPosted {
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 10px;
}

.feed__postCommentsUserImg {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.feed__postCommentsPostedTxt {
  padding: 12px 10px;
  background: #f2f3f7;
  border-radius: 6px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.feed__postCommentsPostedContent {
  width: 100%;
}

.feed__postCommentsActionsWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-right: 15px;
}

.feed__postCommentsActions {
  display: flex;
  flex-flow: row;
  margin-left: 20px;
}

.feed__postCommentsActionTxt {
  color: $civik-dark-grey;
  font-weight: 600;
  margin-right: 15px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.feed__postCommentsDateTxt {
  color: $civik-dark-grey;
  font-weight: 400;
}

.feed__postCommentsLikesTxt {
  color: $civik-dark-grey;
  font-weight: 600;
  margin-top: 1px;
}

.feed__postCommentsLikeWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.feed__postCommentsLikeImg {
  width: 13px;
  height: 13px;
  margin-right: 3px;
}

.feed__postCommentsReplyImg {
  margin-top: 10px;
  width: 18px;
  margin-right: 20px;
}

.feed__postCommentsReplyImgWrap {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  margin-left: 10px;
}

.feed__postCommentsReply {
  // margin-top: 6px;
}

.feed__postCommentsFooter {
  margin-top: 20px;
}

.feed__postCommentEnter {
  margin-top: 5px;
  margin-left: 15px;
}

.mobile__show {
  display: none;
}

.mobile__bottomNavbar {
  display: none;
}

.feed_postVideo {
  overflow: hidden;
  border-radius: 15px;
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 20px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1200px) {
  .Feed {
    margin-top: 70px;
  }

  .mobile__bottomNavbar {
    display: flex;

    box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  }

  .feed__postsContainer {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100% !important;
  }

  .feed__postsContainerWrap {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100% !important;

    &:last-child {
      margin-bottom: 83px;
    }
  }

  .feed__bar,
  .feed__postFilterBar {
    display: none;
  }

  .feed__post {
    overflow-y: hidden;
    width: 100% !important;
    margin-top: 30px;
    // margin-bottom: 1px;

    // height: 140px;
    margin-left: 0px;
    margin-right: 0px;
    background: #ffffff;
    box-shadow: none;
    border-radius: 0px;
    padding: 10px;
  }

  .feed__postBodyPreviewImg {
    width: 105%;
    margin-left: -2.5%;
    margin-right: -2.5%;
    border-radius: 0px;
  }

  .feed__postBodyNewsOverlay {
    width: 105%;
    margin-left: -2.5%;
    margin-right: -2.5%;

    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 15px;
  }

  .dropdown__postOptions {
    right: 10px;
  }

  .mobile__hide {
    display: none;
  }

  .mobile__show {
    display: block;
  }

  .feed__postBody {
    margin-top: 0px;
  }

  .feed__postTypeWrapMobile {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    width: 100%;
    margin-top: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .feed__postTypeWrap {
    text-align: center;
    width: fit-content;
  }

  .mobile__bottomNavbar {
    display: block;
    background-color: rgb(255, 255, 255);
    height: 71px;
    width: 100%;
    position: fixed;
    bottom: 0;

    padding: 15px 20px;

    z-index: 999999;
  }

  .mobile__bottomNavbarIcons {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
  }

  .mobile__bottomNavbar__feedBtn {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .mobile__bottomNavbarIcon {
    margin-bottom: 3px;
    width: 25px;
  }

  .mobile__bottomNavbarIcon__notif {
    width: 18px;
    margin-bottom: 3px;
  }

  .mobile__bottomNavbarIcon__notifBtn {
    margin-left: 20px;
  }

  .mobile__bottomNavbarIcon__feed {
    margin-bottom: 3px;
    width: 26px;
  }

  .mobile__bottomNavbarIcon__myPosts {
    width: 23px;
    margin-bottom: 3px;
  }

  .mobile__bottomNavbarIcon__petitions {
    width: 23px;
    margin-bottom: 3px;
  }

  .mobile__bottomNavbarIcon__feedBtn {
    margin-left: 10px;

    margin-right: 30px;
  }

  .mobile__bottomNavbarIcon__petitionBtn {
    // margin-right: 10px;
  }

  .mobile__bottomNavbarIcon__plusIcon {
    margin-top: -10px;
    // margin-left: 25px;
    // margin-right: 20px;
  }

  .mobile__bottomNavbarIcons__leftSide {
    display: flex;
    flex-flow: row;
  }

  .mobile__bottomNavbarIcons__rightSide {
    display: flex;
    flex-flow: row;
  }

  .mobile__bottomNavbar__defaultBtnTxt {
    font-weight: 500;
    color: $civik-dark-blue;
    font-size: 9px;
  }

  .mobile__bottomNavbar__activeBtnTxt {
    font-weight: 500;
    color: $civik-primary-blue;
    font-size: 9px;
  }

  .feed__post {
    padding-bottom: 0px;
    margin-top: 10px;
  }

  .feed__postActionBarBtn {
  }

  .feed__postBodyTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .feed__postActionBarBtnImg {
    width: 20px;
  }

  .feed__postActionBarBtnTxt {
    font-size: 13px;
  }

  .feed__postBodyTxt {
    font-size: 14px;
  }

  .feed__postProfileImg {
    width: 46px;
    height: 46px;
  }

  .feed__postProfileInnerWrapName {
    font-size: 14px;
  }

  .feed__postProfileInnerWrapDate {
    font-size: 13px;
  }

  .feed__postTypeTxt {
    font-size: 14px;
  }

  .feed__postBodyNewsMetaTitle {
    font-size: 13px;
  }

  .feed__postBodyNewsOverlay {
    height: 85px;
  }
  .feed__postReactionsCommentTxt {
    font-size: 13px;
  }

  .feed__postReactionsCommentStat {
    font-size: 13px;
  }

  .feed__postBodyNewsMetaTitle {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .feed_postVideo {
    margin-left: -2.5%;
    margin-right: -2.5%;
    border-radius: 0px;

    margin-bottom: 20px;
    min-width: 105%;
  }

  .feed__postReactionsStatsTxt {
    font-size: 13px;
  }

  .feed__postBodyTxt {
    // max-width: 300px;
  }
}
