.Navbar {
  position: fixed;

  background-color: #fff;
  width: 100%;
  height: 60px;

  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 99999999999;

  display: flex;
  flex-flow: row;
  align-items: center;
}

//
.navbar__logo {
  height: 30px;
  margin-right: 40px;
}

.navbar__pageLinksWrap {
  margin-left: 120px;
}

.navbar__location {
  padding: 10px 15px;
  background-color: #eef2f5;
  border: none;
  border-radius: 6px;
  color: #6c7289;
  font-weight: 600;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.navbar__searchInput {
  padding: 10px;
  background-color: #eef2f5;
  border: none;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  color: #6c7289;

  &:focus {
    outline: none;
  }
}

.navbar__searchIconContainer {
  padding: 8px;
  background-color: #eef2f5;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.navbar__searchIcon {
}

// feedbtn
.navbar__feedBtnDefault,
.navbar__petitionBtnDefault {
  display: flex;
  flex-flow: row;
  align-items: center;
  transition: 0.5s;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.navbar__petitionBtnDefault {
  margin-left: 25px;
}

.navbar__feedBtnIcon {
  width: 24px;
}

$civik-dark-grey: #6c7289;
$civik-primary-blue: #3699ff;

.navbar__feedBtnTxt {
  font-weight: 500;
  color: $civik-dark-grey;
  margin-left: 10px;
}

.navbar__feedBtnActive {
  padding: 10px;
  border-radius: 6px;
  background-color: #eef2f5;
  margin-left: 25px;
  transition: 0.5s;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

// petitionbtn
.navbar__petitionBtnActive {
  padding: 10px;
  border-radius: 6px;
  background-color: #eef2f5;

  margin-left: 25px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.navbar__petitionBtnIcon {
  width: 19px;
}

.navbar__defaultBtnTxt {
  font-weight: 500;
  color: $civik-dark-grey;
  margin-left: 10px;
}

.navbar__activeBtnTxt {
  font-weight: 500;
  color: $civik-primary-blue;
  margin-left: 10px;
}

// right side
.navbar__elipsisContainer {
  margin-left: 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.navbar__elipsisGreetingTxt {
  font-weight: 500;
  color: $civik-dark-grey;
}

.navbar__dropdown {
  z-index: 1;
  position: absolute;

  border-radius: 4px;

  background: #ffffff;
  box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);

  li {
    list-style: none;

    /* pobup-menu-text-14 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #3f4254;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.navbar__dropdownCity {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
}

.navbar__dropdownBtn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
  width: 100%;

  margin-top: 5px;
  border: none;
  border-top: 1px solid #eef2f5;
  height: 45px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  text-align: left;
  padding-left: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.navbar__dropdownTxtWrap {
  padding: 15px 15px 5px 15px;
  z-index: 999;
}

.navbar__dropdownElipsisLink {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.navbar__dropdownElipsis {
  top: 55px;
  right: 230px;
  width: 170px;
}

.navbar__dropdownPhoto {
  top: 55px;
  right: 110px;
  width: 170px;
}

.navbar__PhotoContainer {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
.navbar__dropdownLocation {
  top: 55px;
  left: 165px;
  width: 150px;

  hr {
    color: #6c7289;
  }

  small {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    color: #6c7289;
  }
}

// .navbar__dropdown {
//   padding: 20px;
//   position: absolute;
//   top: 55px;
//   left: 130px;
//   width: 200px;
//   border-radius: 4px;

//   background: #ffffff;
//   box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);

//   display: flex;
//   flex-flow: column;

// }

.navbar__elipsisNameLetterWrap {
  background-color: #c8e3ff;
  padding: 8px;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.navbar__elipsisNameLetter {
  color: #3699ff;

  font-size: 18px;
}

.navbar__logo {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.notification-badge {
  color: #fff;
  font-size: 12px;
  padding: 2px;
  background-color: red;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-left: -7.5px;
}

.navbar__locationChevronDown {
  margin-left: 13px;
  width: 14px;
}

.navbar__settingDropdown {
  width: 232px;

  padding-top: 7px;
}

.navbar__settingDropdownTxtWrap {
  padding: 22px;
}

.navbar__dropdownIconBtn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
  width: 100%;

  border: none;
  // border-top: 1px solid #eef2f5;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  text-align: left;

  height: 35px;
  width: 202px;

  // padding: 22px;
  margin-left: 15px;

  padding-left: 11px;
  padding-right: 11px;

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #f1faff;
    border-radius: 6px;
    color: #3699ff;
  }
}

.navbar__dropdownIconBtnProfile {
  margin-top: 8px;
}
.navbar__dropdownIconBtnLogout {
  margin-bottom: 8px;
}
.navbar__dropdownGroupSeperator {
  width: 100%;
  /* App Main Pages Background */
  border: 1px solid #f2f3f7;
  margin-top: 8px;
  margin-bottom: 8px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .Navbar {
    display: none;
  }
}
