// variables
$civik-dark-grey: #6c7289;
$civik-primary-blue: #3699ff;

//
.CreatePostModal {
  width: 100%;
}

.postModal__picSection {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.postModal__picSubSection {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.postModal__nameLetterWrap {
  margin-right: 15px;
  background-color: #c8e3ff;
  padding: 8px;
  height: 35px;
  width: 35px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 25px;
}

.postModal__nameLetter {
  color: #3699ff;
  font-size: 18px;
}

.postModal__nameTxt {
  font-size: 16px;
  font-weight: 500;
}

.postModal__changePrivacy {
  margin-left: 15px;
}

.dropdown__privacyOptions {
  // top: 55px;
  // right: 145px;

  width: 225px;
}

.dropdown__topicOptions {
  // top: 55px;
  // right: 145px;

  width: 90%;
}

.dropdown__locationOptions {
  width: 240px;
  // margin-top: 50px;
}

.dropdown__txtWrap {
  padding: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.dropdown__iconOptionWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // margin-bottom: 10px;
  padding: 7px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #f1faff;
    border-radius: 4px;

    .dropdown__iconOptionTxt {
      color: #3699ff;
    }

    .dropdown__iconOptionImg path {
      fill: #3699ff;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.dropdown__iconOptionImg {
}

.dropdown__iconOptionTxt {
  margin-left: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
}

.postModal__changePrivacyDropdown {
  padding: 7px 10px;
  background-color: #eef2f5;
  border: none;
  border-radius: 6px;
  color: #6c7289;
  font-weight: 600;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.postModal__selectTopicDropdown,
.postModal__changeLocationDropdown {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f8fa;
  border: none;
  border-radius: 6px;
  color: #a1a5b7;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 10px;
  width: 100%;
  font-size: 14px;

  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.postModal__changeLocationDropdown {
  width: 240px;
}

.postModal__toggleBar {
  margin-top: 10px;
  padding-bottom: 10px;

  hr {
    color: #d9d9da;
    margin: 0px;
    margin-top: 13px;

    padding: 0px;
  }
}

.postModal__toggleBarTab {
  justify-content: center;
  margin-left: 4px;
  border-bottom: 4px solid #6993ff;
  width: 100px;
  padding-bottom: 10px;
  margin-bottom: -14px;
  z-index: 999;
}

.postModal__toggleBarPostTabDefault {
  border-bottom: none;
}

.postModal__toggleBarPostTabDefaultTxt {
  color: $civik-dark-grey;
  margin-left: 10px;
  font-weight: 500;
}

.postModal__toggleBarTabTxt {
  color: #6993ff;
  margin-left: 10px;
  font-weight: 500;
}

.postModal__toggleBarActive {
  width: 150px;
}

.dropdown__valueTxt {
  color: black;
}

//

.postModal__actionBar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border: 1px solid #f2f3f7;
  border-radius: 6px;
  padding: 10px 20px;
}

.postModal__actionBarDefaultTxt {
  color: $civik-dark-grey;
  margin-right: 20px;
  font-weight: 500;
}

.postModal__actionBarImgIcon {
  margin-right: 15px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.postModal__actionBarVideoIcon {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.postModal__actionBarPrimaryTxt {
  font-weight: 500;
  color: $civik-primary-blue;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

// bottom buttons
.postModal__buttonDivider {
  margin: 0;
  padding: 0;
  margin-top: 35px;
  color: #f2f3f7;
}

.postModal__footer {
  padding-top: 25px;
  padding-bottom: 10px;
}

.postModal__changeLocationTypeContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  // align-items: center;
  margin-bottom: 25px;
}

.postModal__postInTxt {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.postModal__postInCityTxt {
  color: $civik-primary-blue;
  font-weight: 700;
  font-size: 14px;
}

.postModal__changeLocationDropdownCont {
}

.createPostModal_optionTxt {
  // margin-left: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
}

.postModal__toggleBarNewsTab {
  justify-content: center;
  margin-left: 25px;
  border-bottom: 4px solid #aa7762;

  padding-bottom: 10px;
  margin-bottom: -14px;
  z-index: 999;
}

.postModal__toggleBarNewsTabTxt {
  color: #aa7762;
  margin-left: 10px;
  font-weight: 500;
}

.postModal__toggleBarNewsTabDefault {
  border-bottom: none;
  margin-left: 20px;
}

.postModal__toggleBarNewsDefaultTxt {
  color: $civik-dark-grey;
  margin-left: 10px;
  font-weight: 500;
}

// create post
.createPostModal__profileImg {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  margin-left: 20px;
}

.createPostModal__nameLetterWrap {
  margin-right: 15px;
  background-color: #c8e3ff;
  padding: 8px;
  height: 35px;
  width: 35px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createPostModal__profileImg,
.createPostModal__nameLetterWrap,
.createPostModal__nameLetter {
  margin-left: 0;
  text-align: center;
  margin-right: 0;
  font-size: 16px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .CreatePostModal {
    width: 100%;
    // overflow-y: scroll;
  }
  .postModal__changeLocationTypeContainer {
    flex-flow: row wrap;
  }

  .postModal__postInTxt {
    margin-top: 20px;
  }
}
