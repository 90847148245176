.civikModal__header {
  padding: 0px;
  border-bottom: 1px solid #eef2f5;
  padding-bottom: 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
}

.civikModal__headerTitle {
  color: #3f4254;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 20px;
}

.civikModal__body {
  height: 400px;
}

.civikModal__footer {
  border-top: 1px solid #eef2f5;
  padding: 20px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
