.notification-border{
    border-bottom: 1px solid #F2F3F7;
}

.notification {
    font-family: Poppins;
    .highlight {
        background-color: #F5F8FA;
    }
    .header-text {
        color: #464E5F;
        font-weight: 600;
    }
    .title-text {
        color: #464E5F;
    }
    .time-text {
        color: #B5B5C3;
    }
    .description-text {
        color: #80808F;
    }
}