.profileInfo__row {
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
}

.profileInfo__label {
  width: 153px;
  @media only screen and (max-width: 768px) {
    width: 100px;
  }
}
