$civik-dark-grey: #6c7289;
$civik-dark-blue: #3f4254;
$civik-primary-grey: #7e8299;
$civik-primary-blue: #3699ff;

.AddImageModal {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    overflow: auto;
  }
}

.addImageModal__uploadContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  // height: 311px;
  border: 1.5px dashed #00a3ff;

  background-color: #f1faff;
  border-radius: 12px;
  margin: 30px;

  height: 300px;
  @media only screen and (max-width: 768px) {
    height: 250px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.addImageModal__uploadIcon {
  width: 60px;
  align-self: center;
}

// .filepond--root {
//   background-color: #f1faff;
//   width: 100%;
//   min-height: 311px;
//   max-height: 311px;
// }

// .filepond--hopper {
//   background-color: #f1faff;
//   min-height: 311px;
//   max-height: 311px;
// }

// .filepond--wrapper {

//   max-height: 311px;
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   justify-content: center;

//   // height: 311px;
//   border: 1.5px dashed #00a3ff;

//   background-color: #f1faff;
//   border-radius: 12px;
//   margin: 30px;

//   &:hover {
//     cursor: pointer;
//     opacity: 0.7;
//   }
// }

// .filepond--drop-label {
//   min-height: 311px;
// }

// .filepond--txt-wrapper {
//   width: 100%;
// }

// /* bordered drop area */
// .filepond--panel-root {
//   background-color: #f1faff;
//   border: none;
// }

// .filepond--credits {
//   display: none;
// }

/* Upload Document Input  ----------------------------------------------------------------  */
.filepond--wrapper {
}

.doc-input-container {
  background-color: #f1faff;
  border-style: dashed;
  height: 100%;
  border: 1.5px dashed #00a3ff;
  background-color: #f1faff;
  border-radius: 12px;
  margin: 30px;
  display: flex;
  justify-content: center;
  flex-flow: column;

  // padding: 30px;

  // &:hover {
  //   cursor: pointer;
  //   opacity: 0.7;
  // }
}

/* bordered drop area */
.filepond--panel-root {
  border: 4px dashed #9ba9ca;
  opacity: 0;
}

/* Input Placeholder (document upload) ----------------------------------------------------------------  */

// .filepond--drop-label {
//   right: 0;
//   color: #9ba9ca !important;
//   display: flex;
//   justify-content: center;
//   align-self: center;
//   height: 0;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   font-size: 25px;
//   height: 311px;
// }

.filepond--drop-label span {
  cursor: default;
  font-size: 14px !important;

  text-align: center;

  color: #7e8299;
  font-weight: 600;
}

.filepond--file {
  background-color: #ffffff;
  color: #fefefe !important;
  font-size: 16px !important;
}

.filepond--credits {
  display: none;
}

.addImageModal__uploadTxt {
  color: $civik-dark-blue;
  margin-top: 10px;
}

.addImageModal__uploadSubTxt {
  color: $civik-primary-grey;
  font-weight: 500;
}

.addImageModal__uploadedImg {
  // width: 250px;
  height: 300px;
  width: 490px;
  border-radius: 12px;

  @media only screen and (max-width: 768px) {
    height: 250px;
    width: 100%;
  }
}

// loading

.addImageModal_uploadingLoading {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 30px;
  height: 300px;
  @media only screen and (max-width: 768px) {
    height: 250px;
  }
}

// remove pic icons

.addImageModal__uploadedImgRemoveBtn {
  background-color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  float: right;

  margin-top: -305px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.addImageModal__uploadedImgRemoveIconImg {
  width: 25px;
}
