//civik theme variables

// colours
$civik-pimary-dark-grey: #a1a5b7;
$civik-primary-blue: #3699ff;
$civik-primary-light-grey: #eef2f5;
$civik-primary-green: #50cd89;
$civik-dark-text: #3f4254;

$civik-dark-grey2: #7e8299;
$civik-light-blue: #c8e3ff;
$civik-bg-light-blue: rgba(54, 155, 255, 0.1);
$civik-main-pgs-bg: #f2f3f7;
$civik-input-light-blue: #f5f8fa;
$civik-input-text-filled: #5e6278;
