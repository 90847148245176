.MyPosts {
  max-width: 600px;
  margin-top: 30px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .MyPosts {
    // width: 100%;
    max-width: 350px;
    margin-top: 30px;
    overflow-y: hidden;
    border-radius: 6px;
  }

  .profile__title {
    margin-bottom: 20px;
  }
}
